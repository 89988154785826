// eslint-disable-next-line @typescript-eslint/no-var-requires
const Olm = require('@matrix-org/olm')
global.Olm = Olm

export async function loadOlm (): Promise<void> {
  /* Load Olm. We try the WebAssembly version first, and then the legacy,
   * asm.js version if that fails. For this reason we need to wait for this
   * to finish before continuing to load the rest of the app.
   *
   * We also need to tell the Olm js to look for its wasm file at the same
   * level as index.html. It really should be in the same place as the js,
   * ie. in the bundle directory, but as far as I can tell this is
   * completely impossible with webpack. We do, however, use a hashed
   * filename to avoid caching issues.
   */
  return await Olm.init({ locateFile: () => 'https://kipa.to/cloud/olm.wasm' }).then(() => {
    console.log('Using WebAssembly Olm')
  }).catch((e: any) => {
    console.error('Failed to load Olm: trying legacy version', e)
    return new Promise((resolve, reject) => {
      const s = document.createElement('script')
      s.src = 'https://kipa.to/cloud/olm_legacy.js' // XXX: This should be cache-busted too
      s.onload = resolve
      s.onerror = reject
      document.body.appendChild(s)
    }).then(() => {
      // Init window.Olm, ie. the one just loaded by the script tag,
      // not 'Olm' which is still the failed wasm version.
      return window.Olm.init()
    }).then(() => {
      console.log('Using legacy Olm')
    }).catch((e) => {
      console.error('Both WebAssembly and asm.js Olm failed!', e)
    })
  })
}
